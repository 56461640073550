<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-notes"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit && !spinners.loading">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>

      <div class="card-header-actions">
        <CButton color="dark" :to="prevRoute">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <alert-section
        :successAlertMessage="successAlertMessage"
        :dismissSecs="dismissSecs"
        :dismissSuccessAlert="dismissSuccessAlert"
        :errorAlertMessage="errorAlertMessage"
        :showErrorAlert="showErrorAlert"
      />

      <form-summary
        v-if="!isValid"
        class="form-errors alert alert-danger"
        :validator="$v.form"
        :attributes="attributes"
      />

      <CForm v-if="!spinners.loading">
        <CTabs class="tab-menu" :active-tab.sync="activeTab" variant="pills" :vertical="{ navs: 'col-xl-2 col-md-3', content: 'col-xl-10 col-md-9' }">
          <CTab active>
            <template slot="title">
              <CIcon name="cil-task"/> General Information
            </template>

            <div class="d-flex flex-column">
              <!-- country -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CSelect
                  class="w-100 mr-2"
                  label="Country:"
                  placeholder="Please select.."
                  :lazy="false"
                  :options="authCountries"
                  :value.sync="$v.form.countryId.$model"
                />

                <div class="w-100"></div>
              </div>

              <!-- name, vat -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Name:"
                  placeholder="Name"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                  :isValid="checkIfValid('name')"
                />

                <CInput
                  class="w-100"
                  label="VAT (BTW) Number:"
                  placeholder="VAT (BTW) Number"
                  :lazy="false"
                  :value.sync="$v.form.vat.$model"
                  :disabled="invoiceCount > 0"
                >
                  <template #append>
                    <CButton
                      color="info"
                      :disabled="!$v.form.vat.$model || $v.form.vat.$model.length === 0"
                      @click="getCompanyInfo"
                    >
                    <CSpinner v-if="spinners.BtnGetCompanyInfo" size="sm" /> Get Company Info
                    </CButton>
                  </template>
                </CInput>
              </div>

              <!-- address section -->
              <section class="address mb-3">
                <!-- Verify Address From Google -->
                <CInput
                  id="verify_address"
                  :value.sync="verified_address"
                  class="w-100"
                  label="Verify Address From Google:"
                  placeholder="Enter a location"
                  :lazy="false"
                >
                  <template #prepend>
                    <CButton color="info"><CIcon name="cil-location-pin"/></CButton>
                  </template>
                </CInput>

                <!-- Address -->
                <div class="d-flex flex-column flex-md-row">
                  <CTextarea
                    class="w-100"
                    rows="2"
                    label="Address:"
                    placeholder="Street name and house number"
                    :lazy="false"
                    :value.sync="$v.form.address.$model"
                    :isValid="checkIfValid('address')"
                  />
                </div>

                <!-- postal_code, city -->
                <div class="d-flex flex-column flex-md-row">
                  <CInput
                    id="PostalCodeNum"
                    type="number"
                    class="w-100 mr-2"
                    label="Postal Code:"
                    placeholder="Zip or Postal Code"
                    :lazy="false"
                    :value.sync="$v.form.postal_code.$model"
                    :isValid="checkIfValid('postal_code')"
                  />

                  <CInput
                    class="w-100 mr-2"
                    label="City:"
                    placeholder="City"
                    :lazy="false"
                    :value.sync="$v.form.city.$model"
                    :isValid="checkIfValid('city')"
                  />
                </div>
              </section>

              <!-- monthly_limit -->
              <div class="mb-3 d-flex flex-column flex-md-row">
                <CInput
                  class="w-100"
                  label="Monthly Limit:"
                  placeholder="0.00"
                  :lazy="false"
                  :value.sync="$v.form.monthly_limit.$model"
                  :isValid="checkIfValid('monthly_limit')"

                  type="number"
                  step="100"
                  pattern="^\d+(?:\.\d{1,2})?$"
                  onblur="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)"
                >
                  <template #prepend-content><CIcon name="cil-euro" /></template>
                </CInput>

                <div class="w-100"></div>
              </div>

              <!-- eol -->
              <div class="form-group w-100">
                <label class="d-block" style="font-weight: 500">Disabled:</label>
                <CSwitch
                  :checked.sync="$v.form.eol.$model"
                  labelOn="YES"
                  labelOff="NO"
                  color="success"
                  shape="pill"
                  class="mt-020"
                  size="lg"
                  :lazy="false"
                />
              </div>
            </div>
          </CTab>

          <CTab>
            <template slot="title">
              <div v-if="isEdit"><CIcon name="cil-group"/> Users</div>
              <div v-else><CIcon name="cil-user"/> Manager</div>
            </template>

            <CompanyUsers
              v-if="isEdit"
              :active="activeTab == 1"
              :companyId="itemId"
            />

            <div v-else class="col-12 col-xl-6">
              <CInput
                class="w-100"
                label="Name"
                placeholder="Full name"
                :value.sync="$v.form.manager.first_name.$model"
                :isValid="checkIfValid('manager.first_name')"
                autocomplete="given-name"
              >
                <template #prepend-content><CIcon name="cil-user" /></template>
              </CInput>

              <CInput
                class="w-100"
                label="Email"
                placeholder="Email"
                :value.sync="$v.form.manager.email.$model"
                :isValid="checkIfValid('manager.email')"
              >
                <template #prepend-content><CIcon name="cil-at" /></template>
              </CInput>

              <CInput
                class="w-100"
                label="Mobile Number"
                placeholder="Mobile number"
                :value.sync="$v.form.manager.mobile.$model"
                :isValid="checkIfValid('manager.mobile')"
              >
                <template #prepend-content><CIcon name="cil-mobile" /></template>
              </CInput>
            </div>

          </CTab>
        </CTabs>
      </CForm>

      <CSpinner v-else color="primary" />
    </CCardBody>

    <CCardFooter class="sticky">
      <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
        <CButton
          color="primary"
          :disabled="spinners.loading || spinners.BtnSubmit || spinners.BtnGetCompanyInfo"
          @click="submit()"
        >
          <CSpinner v-if="spinners.BtnSubmit" size="sm" /> Submit
        </CButton>
      </div>
    </CCardFooter>

    <mc-spinner :opacity="0.8" v-show="spinners.BtnSubmit" />
  </CCard>

</template>

<script>
import { validationMixin } from "vuelidate"
import { required, requiredIf, minLength, email } from "vuelidate/lib/validators"
import CompanyUsers from '../components/CompanyUsers'

export default {
  name: 'Company',
  components: {
    CompanyUsers
  },

  data() {
    return {
      activeTab: 0,
      form: {},
      isEdit: false,
      spinners: {
        loading: false,
        BtnSubmit: false,
        BtnGetCompanyInfo: false
      },

      itemName: '',
      oldVat: '',


      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Form değişikliklerini izlemek için
      unsavedChanges: false,

      // Google Maps API Autocomplete
      verified_address: '',
      autocomplete_address: null,

      // Vuelidate-error-extractor
      attributes: {
        name: 'Company Name',
        vat: 'VAT (BTW) Number',
        monthly_limit: 'Monthly Limit',
        address: 'Address',
        postal_code: 'Postal Code',
        city: 'City',
        countryId: 'Country',
        'manager.first_name': 'Manager name',
        'manager.email': 'Manager email',
        'manager.mobile': 'Manager mobile number',
      },

      // Get previous page
      prevRoute: null,

      // Invoice Count
      invoiceCount : 0,
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      // Tab 0 (General Information)
      name: { required, minLength: minLength(3) },
      vat: { required },
      monthly_limit: { required },

      address: { required },
      postal_code: { required },
      city: { required },
      countryId: { required },

      eol: {},

      manager: {
        first_name: { required: requiredIf(function() { return this.isEdit == false; }) },
        email: { required: requiredIf(function() { return this.isEdit == false; }), email },
        mobile: { required: requiredIf(function() { return this.isEdit == false; }) },
      }
    }
  },

  // Get previous page
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },

  // Route koruması için
  beforeRouteLeave (to, from , next) {
    if (this.unsavedChanges === true) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      this.unsavedChanges = !answer;
      next(answer) // answer: true or false
    } else {
      next()
    }
  },

  created () {
    this.form = this.getForm()
  },

  watch: {
    'form.countryId': function(newVal, oldVal) {
      this.autocomplete_address.setComponentRestrictions({ country: [this.country?.iso2] });
    },
    // Watching address, postcode, city changes
    // addressFields(newVal, oldVal) {
    // },
  },

  async mounted() {
    this.activeTab = this.$route.query.tab ? parseInt(this.$route.query.tab) : 0;

    // F5 veya başka bir siteye gitmeden önce 'Yaptığınız değişiklikler kaydedilmemiş olabilir.' koruması için
    window.onbeforeunload = () => this.unsavedChanges === true ? true : null;

    this.$watch('form', (newV, oldV) => (this.unsavedChanges = true), {
      deep: true
    })

    // Google Maps API Autocomplete: Place Autocomplete Address Form
    this.autocomplete_address = new google.maps.places.Autocomplete(
      document.getElementById("verify_address"),
      {
        componentRestrictions: { country: [this.country?.iso2] },
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    this.autocomplete_address.addListener("place_changed", this.setPlace);

    if(this.itemId) {
      this.form = this.getForm(this.itemId)
      this.isEdit = true
    }
  },

  computed: {
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return Number(this.$route.params.id) },
    addressFields() {
      return `${this.form.address}|${this.form.postal_code}`;
    },
    country() {
      return this.authCountries.find((item) => item.value == (this.form.countryId || this.$store.state.countryId));
    },
  },

  methods: {
    checkIfValid (fieldName) {
      const fields = fieldName.split(".");
      const field = fields.length > 1
        ? this.$v.form[fields[0]][fields[1]]
        : this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    async submit () {
      // Validate if VAT (BTW) Number is entered
      var isValidVAT = !this.form.vat || (this.oldVat == this.form.vat);

      (this.spinners.BtnSubmit = !isValidVAT) &&
        (isValidVAT = this.form.vat.length == 10) &&
        (await this.VATvalidation().then(
          ({ data }) => (isValidVAT = data.success)
        ));

      this.spinners.BtnSubmit = false;

      if (this.isProduction && !isValidVAT) {
        this.errorAlertMessage = { error: "Invalid VAT (BTW) number!" };
        this.showErrorAlert = true;
        return;
      }

      window.scrollTo(0,0);

      if (!this.isValid) {
        this.validate();
        this.activeTab = 0;
        return;
      }

      if (this.isEdit) {
        await this.update()
      } else {
        await this.store()
      }
    },

    async update () {
      this.spinners.BtnSubmit = true

      await this.$axios.put(this.$backend.MENUTEPAY.COMPANIES.UPDATE.replace("{id}", this.itemId), this.form)
        .then((response)=>{
          this.itemName = response.data.name
          this.successAlertMessage = {
            itemName: this.itemName,
            message: `company has been successfully updated.`
          }
          this.dismissSuccessAlert = this.dismissSecs
          this.showErrorAlert = false
        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data
          this.showErrorAlert = true
        })
        .finally(() => {
          this.spinners.BtnSubmit = false
          this.unsavedChanges = false
        });
    },

    async store () {
      this.spinners.BtnSubmit = true

      await this.$axios.post(this.$backend.MENUTEPAY.COMPANIES.STORE, this.form)
        .then((response)=>{
          this.itemName = response.data.company?.name
          this.successAlertMessage = {
            itemName: this.itemName,
            message: `company has been successfully added.`
          }
          this.dismissSecs = 3
          this.dismissSuccessAlert = this.dismissSecs
          this.showErrorAlert = false

          this.unsavedChanges = false

          setTimeout(() => {
            this.$router.push({ name: 'Companies' })
          }, 3000)
        })
        .catch((error)=>{
          this.errorAlertMessage = error.response.data ?? error.response;
          this.showErrorAlert = true
        })
        .finally(() => {
          this.spinners.BtnSubmit = false
        });
    },

    validate () {
      this.$v.$touch()
    },

    reset () {
      this.form = this.getForm()
      this.spinners.BtnSubmit = false
      this.showErrorAlert = false
      this.$v.$reset()
    },

    getForm (id = null) {
      var form = {
        // Tab 0 (General Information)
        name: '',
        vat: null,
        monthly_limit: 1000,

        address: null,
        postal_code: null,
        city: null,
        countryId: this.$store.state.countryId,

        eol: true,
      };

      if(id) {
        this.spinners.loading = true

        this.$axios.get(this.$backend.MENUTEPAY.COMPANIES.SHOW.replace("{id}", this.itemId))
          .then((response) => {
            this.itemName = response.data.name

            // Tab 0 (General Information)
            form.name = response.data.name;
            this.oldVat = form.vat = response.data.vat;
            form.monthly_limit = response.data.monthly_limit;

            form.address = response.data.address;
            form.postal_code = response.data.postal_code;
            form.city = response.data.city;
            form.countryId = response.data.countryId || this.$store.state.countryId;

            form.eol = response.data.eol;

            // Invoice Count
            this.invoiceCount = response.data.invoices_count;

            this.spinners.loading = false;
          })
          .catch((error) => {
            this.$toast.error(`${error.response.data.message}`);
            setTimeout(() => { this.$router.push({ name: 'Companies' }) }, 3000);
          })
          .finally(() => { this.unsavedChanges = false })
      } else {
        form.manager = {
          first_name: null,
          email: null,
          mobile: null,
        }
      }

      return form;
    },

    async VATvalidation() {
      return await this.$axios
        .post(this.$backend.MENUTEPAY.COMPANIES.VAT_VALIDATION, {
          country_code: this.country?.iso2.toUpperCase(),
          vat_number: this.form.vat,
        })
    },
    async getCompanyInfo() {
      this.spinners.BtnGetCompanyInfo = true

      await this.VATvalidation()
        .then(({data}) => {
          if (data.response) {

            const name = data.response.name.replace(/\u00a0/g, ' ');
            const address = data.response.address.replace(/\u00a0/g, ' ');

            const [firmType, ...firmName] = name.includes('|')
              ? name.split('|').map(part => part.trim())
              : name.split(' ');

            // this.form.firma_type = firmType;
            this.form.name = firmName.join(" ");


            // address: "Zeven Septemberlaan 7\n3550 Heusden-Zolder"
            const [firmAddress, postcodeAndCity] = address.split('\n');
            const [firmPostcode, ...firmCity] = postcodeAndCity.split(' ');

            this.form.address = firmAddress;
            this.form.postal_code = firmPostcode;
            this.form.city = firmCity.join(" ");

            this.$toast.success(`Valid VAT number`);
          } else {
            this.$toast.error(`Invalid VAT (BTW) number!`);
          }
        })
        .catch((error)=>{
          this.$toast.error(`${error.response.data.message}`);
        })
        .finally(() => {
          this.spinners.BtnGetCompanyInfo = false
        });
    },



    setPlace() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete_address.getPlace();

      let street_number = "";
      let route = "";
      let postcode = "";
      let locality = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street_number = component.long_name;
            break;
          }

          case "route": {
            route = component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            locality = component.long_name;
            break;
          }

          // case "administrative_area_level_1": {
          //   console.log("state: ", component.short_name);
          //   break;
          // }

          // case "country": {
          //   console.log("country: ", component.long_name);
          //   console.log("country: ", component.short_name);
          //   break;
          // }
        }
      }

      // street_number:  25
      // route:  Sint-Willibrordusplein
      // locality:  Heusden-Zolder
      // state:  Vlaams Gewest
      // country:  Belçika
      // country:  BE
      // address1:  Sint-Willibrordusplein 25
      // postcode:  3550
      // latitude:  51.0331523
      // longitude:  5.2814117

      this.verified_address = place.formatted_address;
      this.form.address = `${route} ${street_number}`;
      this.form.postal_code = postcode.replace(/\D/g, "");
      this.form.city = locality;
    },
  }
}
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 99;
}
form .nav-pills .c-icon {
  bottom: 0.1rem;
  position: relative;
}
.mt-020 {
  margin-top: 0.20rem;
}
section.address {
  background: #F8F0DF;
  padding: 1rem;
  border-radius: 0.5rem;
}
</style>

<style>
.tab-menu > div:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #d8dbe0;
}


/* Vuelidate-error-extractor */
.form-errors div label::before {
  content: "●";
  margin-right: 5px;
}
.form-errors div:nth-child(1):before {
  display: block;
  content: "Validation Errors:";
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.form-errors:empty {
  display: none;
}


.email.form-group > input {
  text-transform: lowercase;
}
.email.form-group > input::-webkit-input-placeholder,
.email.form-group > input:-moz-placeholder,
.email.form-group > input::-moz-placeholder,
.email.form-group > input:-ms-input-placeholder,
.email.form-group > input::placeholder {
    text-transform: none;
}


/* Remove Number Input's Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
#PostalCodeNum::-webkit-outer-spin-button,
#PostalCodeNum::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

/* Firefox */
#PostalCodeNum {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
