<template>
  <CDataTable
    class="table-company-users"
    striped
    hover
    :items="loadedItems"
    :fields="fields"
    :loading="loading"
    :noItemsView="{
      noResults: 'No filtering results are available!',
      noItems: 'No users found!',
    }"
    clickable-rows
    @row-clicked="rowClicked"
  >
    <!-- Role -->
    <template #role="{item}">
      <td>
        <strong>{{ item?.company_role }}</strong>
      </td>
    </template>

    <!-- ID -->
    <template #id="{item}">
      <td>
        #{{ item?.id }}
      </td>
    </template>

    <!-- User Icon -->
    <template #image_icon="{item}">
      <td>
        <CLink @click="imageClicked(item)">
          <CImg
            :src="item.image_icon || item.gravatar"
            class="c-avatar-img"
            style="width: 36px; height: 36px"
            placeholderColor="lightgray"
          />
        </CLink>
      </td>
    </template>

    <!-- Name -->
    <template #name="{item}">
      <td>
        {{ item.first_name }}
      </td>
    </template>

    <!-- Contact -->
    <template #contact="{item}">
      <td>
        <div><CIcon name="cil-at" class="mr-1" />{{ item.email }}</div>
        <div><CIcon name="cil-phone" class="mr-1" />{{ item.mobile }}</div>
      </td>
    </template>

    <!-- MenutePay Verified -->
    <template #menutepay_verified_at="{item}">
      <td>
        <span v-if="item.menutepay_verified_at">{{ new Date(item.menutepay_verified_at).toLocaleString() }}</span>
        <span v-else>
          <CLink @click="resendActivationEmail(item.id)">Resend Activation Email</CLink>
        </span>
      </td>
    </template>

    <!-- Email Verified -->
    <template #email_verified_at="{item}">
      <td>
        <span v-if="item.email_verified_at">{{ new Date(item.email_verified_at).toLocaleString() }}</span>
        <span v-else>Not verified</span>
      </td>
    </template>
  </CDataTable>
</template>

<script>
export default {
  name: "CompanyUsers",
  props: {
    active: { default: false, type: Boolean },
    companyId: { default: null, type: Number },
  },
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "role", _style: "min-width: 96px; width: 96px;" },
        { key: "id", label: "ID", _style: "min-width: 60px; width: 60px;" },
        { key: "image_icon", label: "", sorter: false,  _style: "min-width: 60px; width: 60px;" },
        { key: "name", _style: "min-width: 140px;" },
        { key: "contact", _style : "min-width: 200px;" },
        { key: "menutepay_verified_at", label: "MenutePay Verified", _style : "min-width: 120px;" },
        { key: "email_verified_at", label: "Email Verified", _style : "min-width: 120px;" },
      ],

      loading: false,
    };
  },

  watch: {
    active: async function (val) {
      if (val) {
        await Promise.all([this.getAllItems()]);
      }
    },
  },

  computed: {

  },

  methods: {
    async getAllItems() {
      this.loading = true;
      await this.$axios
        .get(this.$backend.MENUTEPAY.COMPANIES.USERS.replace("{id}", this.companyId), {
          params: { country: 1 },
        })
        .then(({data}) => {
          this.loadedItems = data;
          Object.keys(data).map(function(key, index) {
            let item = data[index];
            item._classes = !item.menutepay_verified_at ? 'table-warning' : '';
          });
        })
        .finally(() => { this.loading = false; });
    },

    rowClicked(item, index, column, e) {
      const exclude = [
        "INPUT",
        "LABEL",
        "BUTTON",
        // "svg",
        // "path",
        "A",
        // "IMG",
        // "SPAN",
        // "DIV",
        "rect",
        // "STRONG",
      ];

      if (!exclude.includes(e.target.tagName)) {
        this.$router.push({ name: 'User', params: { id: item.id } })
      }
    },

    async resendActivationEmail(userId) {
      try {
        await this.$axios.post(this.$backend.MENUTEPAY.COMPANIES.RESEND_ACTIVATION_EMAIL
          .replace("{id}", this.companyId).replace("{user_id}", userId));
        this.$toast.success("Activation email has been resent.");
      } catch (error) {
        this.$toast.error(`${error.response.data.message}`);
      }
    },
  },
};
</script>

<style>
.table-company-users td {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}
</style>
